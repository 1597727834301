/* Custom table container */
.custom-table-container {
    margin: 20px auto;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Custom table head */
  .custom-table-head {
    background-color: #f5f5f5;
  }
  
  /* Custom table head cells */
  .custom-table-head-cell {
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
  
  /* Custom table body rows */
  .custom-table-row {
    &:nth-of-type(odd) {
      background-color: #fafafa;
    }
  }
  
  /* Custom table body cells */
  .custom-table-cell {
    font-size: 14px;
    color: #555;
  }
  
  /* Custom table hover effect */
  .custom-table-row:hover {
    background-color: #f0f0f0;
  }
  
  /* Custom table cell padding */
  .custom-table-cell {
    padding: 12px 16px;
  }
  